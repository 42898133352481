@font-face {
  font-family: Satoshi-Medium;
  src: url(../../fonts/Satoshi-Medium.otf);
}

body {
  overflow: visible;
}

.react-pdf-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pdf-background {
  width: 100%;
  overflow: visible;
  position: relative;
}

.first-slide {
  position: absolute;
  left: 0;
  z-index: -2;
  width: calc(100% - 24px);
  box-shadow: 0px 0px 40px 0px rgba(0, 10, 51, 0.15),
    0px 0px 80px 0px rgba(0, 10, 51, 0.05);
  margin: 12px;
}

.loading {
  position: absolute;
  left: 50%;
  top: 4px;
  z-index: -1;
  width: auto;
  height: auto;
  background: rgba(13, 19, 26, 0.55);
  backdrop-filter: blur(12px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  padding: 8px 24px 8px 16px;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.loading-container {
  min-height: 40px;
  display: flex;
  align-items: center;
}

.loading-content {
  overflow: visible;
}

.loading-title {
  font-size: 17px;
  line-height: 20px;
  font-family: Satoshi-Medium, sans-serif;
  color: #ffffff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-description {
  width: max-content;
  font-size: 14px;
  line-height: 20px;
  font-family: Satoshi-Medium, sans-serif;
  color: #ffffffaa;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 414px) {
  .loading-title {
    width: max-content;
    margin-bottom: 4px;
  }

  .loading-description {
    width: auto;
    line-height: 18px;
  }

  .loading-content {
    height: fit-content;
  }
}

.first-page {
  margin-top: 12px;
  margin-bottom: 0;
}

.other-pages {
  box-shadow: 0px 0px 40px 0px rgba(0, 10, 51, 0.15),
    0px 0px 80px 0px rgba(0, 10, 51, 0.05);
  margin-top: 12px;
  margin-bottom: 0;
}

/*  Loader */

.lds-ring {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  min-width: 20px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;

  border: 1.5px solid #fff;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #fff transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
