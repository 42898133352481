@font-face {
  font-family: Satoshi-Regular;
  src: url(../../fonts/Satoshi-Regular.otf);
}
@font-face {
  font-family: Satoshi-Medium;
  src: url(../../fonts/Satoshi-Medium.otf);
}

.Example__container__document {
  margin: 1em 0;
}

.Example__container__document .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.Example__container__document .react-pdf__Page {
  max-width: calc(100% - 2em);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
  margin: 1em;
}

.Example__container__document .react-pdf__Page canvas {
  max-width: 100%;
  height: auto !important;
}

.Example__container__document .react-pdf__message {
  padding: 20px;
  color: white;
}

body {
  margin: 0;
  padding: 0;
  width: 100%;
}

h1 {
  font-family: Satoshi-Medium, sans-serif;
  font-size: 100px;
  line-height: 120px;
  letter-spacing: -4px;
  font-weight: 500;
  color: #040505;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 80%;
  margin: 0;
  padding: 0;
}

p {
  font-family: Satoshi-Regular, sans-serif;
  font-size: 20px;
  line-height: 36px;
  font-weight: 400;
  color: #040505;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 80%;
  margin: 0;
  padding: 0;
}

.background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  gap: 40px;
}

.buttons a {
  text-decoration: none;
}

.primary {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 28px 16px 16px;
  background-image: url(../../images/button-background.png);
  background-size: cover;
  color: #ffffff;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 0;
  font-family: Satoshi-Medium, sans-serif;
  font-size: 22px;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease-in-out;
}

.primary:hover {
  filter: opacity(85%);
  transition: all 0.2s ease-in-out;
}

.primary:active {
  filter: opacity(75%);
  transition: all 0.2s ease-in-out;
}

.secondary {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 16px 28px 16px 16px;
  background: rgba(0, 8, 16, 0.02);
  color: #040505;
  border: none;
  cursor: pointer;
  outline: inherit;
  margin: 0;
  font-family: Satoshi-Medium, sans-serif;
  font-size: 22px;
  line-height: 40px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 0.2s ease-in-out;
}

.secondary:hover {
  background: rgba(0, 8, 16, 0.04);
  transition: all 0.2s ease-in-out;
}

.secondary:active {
  background: rgba(0, 8, 16, 0.08);
  transition: all 0.2s ease-in-out;
}

.caption {
  font-family: Satoshi-Regular, sans-serif;
  font-size: 20px;
  line-height: 36px;
  color: #757576;
}

.caption a {
  font-family: Satoshi-Medium, sans-serif;
  color: #040505;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
}

.caption a:hover {
  color: #a64dff;
  transition: all 0.2s ease-in-out;
}

@media only screen and (max-width: 647px) {
  .background {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
    background-position: 50% 50%;
  }

  .container {
    width: calc(100% - 48px);
    margin-left: 24px;
    margin-right: 24px;
    margin-top: 24px;
    background: none;
    z-index: 1;
  }

  .spacer-large {
    height: 40px;
    min-height: 40px;
  }

  .spacer-small {
    height: 32px;
    min-height: 32px;
  }

  h1 {
    font-size: 72px;
    line-height: 80px;
    width: 100%;
  }

  p {
    font-size: 18px;
    line-height: 34px;
    width: 100%;
  }

  .buttons {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .buttons a {
    width: 100%;
  }

  .primary {
    width: 100%;
  }

  .secondary {
    width: 100%;
  }

  .ukraine {
    margin-top: 80px;
    width: 100%;
    margin-bottom: 32px;
  }

  .caption {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 648px) and (max-width: 1024px) {
  .background {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: visible;
    background-position: 50% 100%;
  }

  .container {
    width: calc(100% - 80px);
    margin-left: 40px;
    margin-right: 40px;
    margin-top: 40px;
    background: none;
    z-index: 1;
  }

  .spacer-large {
    height: 40px;
    min-height: 40px;
  }

  .spacer-small {
    height: 32px;
    min-height: 32px;
  }

  h1 {
    width: 100%;
    font-size: 88px;
    line-height: 100px;
  }

  p {
    font-size: 18px;
    line-height: 34px;
    width: 100%;
  }

  .buttons {
    width: 100%;
    flex-direction: column;
    gap: 16px;
  }

  .buttons a {
    width: 100%;
  }

  .primary {
    width: 100%;
  }

  .secondary {
    width: 100%;
  }

  .ukraine {
    margin-top: 80px;
    width: 100%;
    margin-bottom: 56px;
  }

  .caption {
    font-size: 16px;
    line-height: 24px;
  }
}

@media only screen and (min-width: 1025px) {
  body {
    max-width: 100vw;
    max-height: 90vh;
    overflow: hidden;
  }

  .background {
    position: absolute !important;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    overflow: hidden;
  }

  .container {
    width: 80%;
    margin-left: 10%;
    margin-right: 10%;
    margin-top: 100px;
    background: none;
    z-index: 1;
  }

  .spacer-large {
    height: 64px;
    min-height: 64px;
  }

  .spacer-small {
    height: 32px;
    min-height: 32px;
  }

  .ukraine {
    position: absolute;
    bottom: 52px;
    width: 80%;
  }
}
